import axios from 'axios'

const INDICATORS_API_URL = process.env.REACT_APP_API_URL_INDICATORS
const INDICATORS_API_KEY = process.env.REACT_APP_API_KEY_INDICATORS

const HUBSPOT_API_URL = process.env.REACT_APP_API_URL_HUBSPOT
const HUBSPOT_API_KEY = process.env.REACT_APP_API_KEY_HUBSPOT

const SIMULATOR_API_URL = process.env.REACT_APP_URL_API_SIMULATOR
const HUBSPOT_API_URL_CARF = process.env.REACT_APP_API_URL_HUBSPOT_CARGAF
const HUBSPOT_API_URL_GC = process.env.REACT_APP_API_URL_HUBSPOT_CARGAF

export const env = (KEY) => process.env[`REACT_APP_${KEY}`]

const dau = axios.create({
  baseURL: env('API_URL_DAU'),
  headers: {
    api_key_header: env('API_KEY_DAU'),
  },
})

const simulatorAxiosInstance = axios.create({
  baseURL: SIMULATOR_API_URL,
  headers: {
    api_key_header: HUBSPOT_API_KEY,
  },
})

const indicatorsAxiosInstance = axios.create({
  baseURL: INDICATORS_API_URL,
  headers: {
    api_key_header: INDICATORS_API_KEY,
  },
})

const hubspotAxiosInstance = axios.create({
  baseURL: HUBSPOT_API_URL,
  headers: {
    api_key_header: HUBSPOT_API_KEY,
  },
})

const hubspotcfAxiosInstance = axios.create({
  baseURL: HUBSPOT_API_URL_CARF,
  headers: {
    api_key_header: HUBSPOT_API_KEY,
  },
})

const hubspotGcAxiosInstance = axios.create({
  baseURL: HUBSPOT_API_URL_GC,
  headers: {
    api_key_header: HUBSPOT_API_KEY,
  },
})

export {
  dau,
  simulatorAxiosInstance,
  indicatorsAxiosInstance,
  hubspotAxiosInstance,
  hubspotcfAxiosInstance,
  hubspotGcAxiosInstance,
}
