import React, { Fragment, Suspense, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import routes from 'routes/content'
import useAuth from 'hooks/useAuth'
import Loader from 'components/App/Loader'
import { Helmet } from 'react-helmet'
import Hotjar from '@hotjar/browser'

const Content = () => {
  useAuth()
  const { pathname } = useLocation()
  const currentRoute = routes.find((route) => route.path === pathname)?.name ?? ''

  useEffect(() => {
    Hotjar.stateChange(pathname)
  }, [pathname])
  return (
    <Fragment>
      <Helmet>
        <title>
          {currentRoute && pathname !== '/'
            ? `${currentRoute} | Propital Simulador`
            : 'Propital Simulador'}
        </title>
      </Helmet>
      <Suspense fallback={<Loader />}>
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              name={route.name}
              element={
                <div className="animate-fade">
                  <route.component />
                </div>
              }
            />
          ))}
        </Routes>
      </Suspense>
    </Fragment>
  )
}

export default React.memo(Content)
