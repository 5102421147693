import dayjs from 'dayjs'

// Genera un número aleatorio entero entre un rango mínimo y máximo
export const random = (min = 0, max = 100) => Math.floor(Math.random() * (max - min + 1) + min)

// Aplica primera letra mayúscula a un string
export const capitalize = (string = '') => string.charAt(0).toUpperCase() + string.slice(1)

// Aplica la primera letra mayúscula a cada palabra de un string
export const capitalizeCombo = (string = '') =>
  string
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

// Redirecciona a los componentes que no estan en el scope de React Router
export const redirect = (path) => window.location.replace(path)

// Formatea un número a entero con delimitadores de punto
export const formatRoundPrice = (str) => {
  const num = Math.round(parseFloat(str))
  const formattedNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  return `${formattedNum}`
}

// Limpia un objeto de todas las propiedades con valor undefined
export const cleanObject = (obj) => {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key]
    }
  }
  return obj
}

// Calcula la ganancia anual de un número y un porcentaje dado
export const calculateAnnualProfit = (amount, percentage) => {
  const profit = amount + (amount * percentage) / 100
  return profit - amount
}

// Devuelve solo el número de días restantes entre una fecha y la actual
export const calculateDaysLeft = (futureDate) => {
  return dayjs(futureDate).diff(dayjs(), 'day')
}

// Devuelve el día y la hora
export const formatDatesWithHour = (date) => {
  return dayjs(date).format('DD/MM/YYYY HH:mm')
}

// Devuelve el día
export const formatDates = (date) => {
  return dayjs(date).format('DD/MM/YYYY')
}

// Devuelve true/false dependiendo del entorno
export const isProduction = () => !window.location.href.includes('localhost' | 'amplify')
