import React from 'react'
import { Navbar } from '@nextui-org/react'
import { useLocation } from 'react-router-dom'
import contentRoutes from 'routes/content'
import Brand from './Brand'
import Links from './Links'
import MobileMenu from './MobileMenu'
import './styles.css'

const Nav = () => {
  const { pathname } = useLocation()
  return (
    <Navbar
      className="animate-fade bg-background min-h-[10vh] shadow-propital dark:shadow-none"
      isBordered
    >
      <Brand />
      <Links routes={contentRoutes} pathname={pathname} />
      <MobileMenu routes={contentRoutes} pathname={pathname} />
    </Navbar>
  )
}

export default Nav
