import React from 'react'
import { NavbarBrand, NavbarContent, NavbarMenuToggle } from '@nextui-org/react'
import LogoDesktop from 'assets/images/app/logo-desktop.png'
import LogoDesktopDark from 'assets/images/app/logo-desktop.png'
import LogoMobile from 'assets/images/app/logo-mobile.png'
import { useTheme } from 'next-themes'
import useLayout from 'hooks/useLayout'

const Brand = () => {
  const { theme } = useTheme()
  const { layout } = useLayout()
  const { width } = layout
  const logo =
    width < 768 && width > 640 ? LogoMobile : theme === 'dark' ? LogoDesktopDark : LogoDesktop
  return (
    <NavbarContent>
      <NavbarMenuToggle className="sm:hidden" />
      <NavbarBrand>
        <img src={logo} alt="Logo" className="h-[4vh]" />
      </NavbarBrand>
    </NavbarContent>
  )
}

export default Brand
