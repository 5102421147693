import React from 'react'
import LogoPropital from 'assets/images/app/logo-propital.png'
import AwsStartups from 'assets/images/app/aws-startups.png'
import { Icon } from '@iconify/react'

const Footer = () => {
  const añoActual = new Date().getFullYear()
  const sections = [
    {
      title: 'Propital',
      content: () => (
        <div className="flex flex-col space-y-1">
          <p>{`Propital ${añoActual}. Todos los derechos`}</p>
          <p>reservados</p>
        </div>
      ),
    },
    {
      title: 'Social',
      content: () => (
        <div className="flex space-x-2">
          <a href="https://www.facebook.com/propital" target="_blank" rel="noreferrer">
            <Icon icon="la:facebook" className="min-h-[24px] min-w-[24px]" />
          </a>
          <a href="https://www.instagram.com/propital" target="_blank" rel="noreferrer">
            <Icon
              icon="ant-design:instagram-outlined"
              className="p-[3px] min-h-[24px] min-w-[24px] rounded-full bg-white text-secondary"
            />
          </a>
          <a href="https://twitter.com/propital" target="_blank" rel="noreferrer">
            <Icon icon="ri:twitter-x-fill" className="min-h-[24px] min-w-[24px]" />
          </a>
          <a href="https://www.linkedin.com/company/propital" target="_blank" rel="noreferrer">
            <Icon icon="la:linkedin" className="min-h-[24px] min-w-[24px]" />
          </a>
          <a href="https://wa.link/wqvotz" target="_blank" rel="noreferrer">
            <Icon icon="basil:whatsapp-outline" className="min-h-[24px] min-w-[24px]" />
          </a>
        </div>
      ),
    },
    {
      title: 'Contacto',
      content: () => (
        <div className="flex flex-col space-y-1">
          <p>contacto@propital.com</p>
          <p>Agenda una reunión</p>
        </div>
      ),
    },
    {
      title: 'Aprende más',
      content: () => (
        <div className="flex flex-col space-y-1">
          <a href="https://www.propital.com/real-estate-mastery" target="_blank" rel="noreferrer">
            <p>Academy</p>
          </a>
          <a href="https://ww2.propital.com/blog" target="_blank" rel="noreferrer">
            <p>Blog de Inversiones</p>
          </a>
        </div>
      ),
    },
    {
      title: 'Respaldados por',
      content: () => <img src={AwsStartups} className="h-11" alt="AWS Startups" />,
    },
  ]
  return (
    <footer className="flex items-center justify-center h-full px-3 py-12 text-center text-white md:text-left md:py-24 bg-secondary">
      <div className="flex flex-col items-start space-y-9 md:space-y-0 md:flex-row md:space-x-9">
        {sections.map((section, index) => (
          <div key={index} className="mx-auto md:mx-0">
            {index === 0 ? (
              <img
                src={LogoPropital}
                className="h-[28px] mx-auto md:mx-0 mb-4"
                alt="Logo Propital"
              />
            ) : (
              <h3 className="mb-4 text-xl font-bold h-[28px]">{section.title}</h3>
            )}
            {section.content()}
          </div>
        ))}
      </div>
    </footer>
  )
}

export default Footer
