import React, { Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Listeners } from './components'
import Layout from 'layout/index'
import routes from 'routes/app'
import useAuth from 'hooks/useAuth'
import Loader from 'components/App/Loader'

const App = () => {
  useAuth()
  return (
    <HashRouter>
      <Listeners.DAU />
      <Suspense fallback={<Loader isAppLoader />}>
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              name={route.name}
              element={route.component}
              exact
            />
          ))}
          <Route path="*" name="Inicio" element={<Layout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
