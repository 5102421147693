import { useEffect, useState, useCallback } from 'react'
import { getAuxIp, isProd } from 'helpers/App'
import { detect } from 'detect-browser'
import { useLocation } from 'react-router-dom'
import { sendDAUEvents } from 'api/DAU'
import useDate from 'hooks/useDate'

const DAU = ({ children }) => {
  // Cada 10 segundos se envían los eventos a la API
  const seconds = 10 * 1000

  // Los eventos persisten en el localStorage
  const [events, setEvents] = useState(
    localStorage.getItem('events') ? JSON.parse(localStorage.getItem('events')) : [],
  )

  const { pathname } = useLocation()
  const { getTimestamp } = useDate()
  const { name, os } = detect()

  // Agrega un evento al array de eventos
  const addEvent = async () => {
    const event = {
      urlvisited: window.location.href,
      account: 'guest',
      event: getTimestamp(),
      ip: await getAuxIp(),
      browser: name,
      os,
    }
    const updatedEvents = [...events, event]
    localStorage.setItem('events', JSON.stringify(updatedEvents))
    setEvents(updatedEvents)
  }

  // Envía los eventos a la API si no es ambiente de desarrollo
  const sendEvents = useCallback(async () => {
    // Si no hay eventos, no enviar nada
    if (events.length === 0) return
    if (!isProd()) return
    try {
      await sendDAUEvents({ event: events })
      setEvents([])
      localStorage.removeItem('events')
    } catch (error) {
      console.error('DAU ERROR:', error)
    }
  }, [events])

  // Cada 10 segundos se envían los eventos a la API
  useEffect(() => {
    const intervalId = setInterval(() => {
      sendEvents()
    }, seconds)

    return () => clearInterval(intervalId)
  }, [sendEvents])

  // Cada vez que se cambie de ruta, se genera un evento
  useEffect(() => {
    addEvent()
  }, [pathname])

  return children
}

export default DAU
