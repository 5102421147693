import { useEffect, useState } from 'react'
import { Layout } from 'helpers/index'

const useLayout = () => {
  const [layout, setLayout] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    breakpoint: Layout.getTailwindBreakpoints(),
  })

  useEffect(() => {
    const handleResize = () => {
      clearTimeout(handleResize.timeoutId)
      handleResize.timeoutId = setTimeout(() => {
        const newLayout = {
          width: window.innerWidth,
          height: window.innerHeight,
          breakpoint: Layout.getTailwindBreakpoints(),
        }
        setLayout(newLayout)
      }, 500)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    layout,
  }
}

export default useLayout
