import React from 'react'
import { Link, NavbarContent, NavbarItem } from '@nextui-org/react'
import PropTypes from 'prop-types'

const Links = ({ routes = [], pathname = '' }) => {
  return (
    <NavbarContent className="hidden gap-4 sm:flex" justify="center">
      {routes.map((item, index) => {
        const isCurrentPath = pathname === item.path
        if (!item.showNavigate) return null
        return (
          <NavbarItem className="flex" key={`${item}-${index}`}>
            <Link
              href={item.linkTo}
              className={`${
                isCurrentPath ? 'text-primary font-semibold' : 'text-inactive hover:text-primary'
              }`}
            >
              {/* <Icon className="mx-auto mr-1 h-[24px] w-[24px]" icon={item.icon} /> */}
              {item.name}
            </Link>
          </NavbarItem>
        )
      })}
    </NavbarContent>
  )
}

Links.propTypes = {
  routes: PropTypes.array.isRequired,
  pathname: PropTypes.string.isRequired,
}

export default Links
