import React from 'react'
import PropTypes from 'prop-types'

const Header = ({ title, subtitle, classNames }) => {
  return (
    <div className="flex flex-col w-full space-y-6 text-center">
      <h1
        className={
          classNames?.title ?? 'text-4xl font-extrabold text-secondary dark:text-foreground'
        }
      >
        {title}
      </h1>
      {subtitle && (
        <h2 className={classNames?.subtitle ?? 'text-secondary dark:text-foreground'}>
          {subtitle}
        </h2>
      )}
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  classNames: PropTypes.object,
}

export default Header
