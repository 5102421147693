import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { NextUIProvider } from '@nextui-org/react'
import { ThemeProvider } from 'next-themes'
import { Toaster } from 'react-hot-toast'
import { Amplify } from 'aws-amplify'
import { Provider } from 'react-redux'
import store from 'providers/Redux/store'
import awsConfig from './aws-exports'
import './index.css'
import Hotjar from '@hotjar/browser'

const renderApp = () => {
  Amplify.configure(awsConfig)
  const root = ReactDOM.createRoot(document.getElementById('root'))
  const pathname = window.location.href

  const siteId = 3709595
  const hotjarVersion = 6
  !pathname.includes('amplify') && Hotjar.init(siteId, hotjarVersion)

  root.render(
    <React.StrictMode>
      <NextUIProvider>
        <ThemeProvider attribute="class" defaultTheme="light" themes={['light', 'dark']}>
          <Provider store={store}>
            <main className="text-foreground bg-background">
              <App />
              <Toaster position="bottom-center" />
            </main>
          </Provider>
        </ThemeProvider>
      </NextUIProvider>
    </React.StrictMode>,
  )
}

renderApp()
