import dayjs from 'dayjs'
import es from 'dayjs/locale/es'
import localeData from 'dayjs/plugin/localeData'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Utils } from 'helpers'

const useDate = () => {
  dayjs.locale(es)
  dayjs.extend(localeData)
  dayjs.extend(relativeTime)

  const months = dayjs.months()
  const weekDays = dayjs.weekdays()

  const getMonths = () => {
    return months.map((item) => Utils.capitalize(item))
  }

  const getWeekDays = () => {
    return weekDays.map((item) => Utils.capitalize(item))
  }

  const getTimeAgo = (date) => {
    return dayjs(date).fromNow()
  }

  const getTimestamp = () => {
    return dayjs().format('YYYY-MM-DD HH:mm:ss')
  }

  return {
    getMonths,
    getWeekDays,
    getTimeAgo,
    getTimestamp,
  }
}

export default useDate
