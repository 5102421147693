import React, { Fragment } from 'react'
import { App } from 'components'

const Layout = () => {
  return (
    <Fragment>
      <App.Nav />
      <div className="grid grid-cols-12">
        <div className="col-span-12 p-3 bg-background min-h-[90vh]">
          <App.Content />
        </div>
      </div>
      <App.Footer />
    </Fragment>
  )
}

export default Layout
