export const getTailwindBreakpoints = () => {
  const breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
  }
  const windowWidth = window.innerWidth
  return Object.keys(breakpoints).find((key) => windowWidth < breakpoints[key]) || '2xl'
}
