import { lazy } from 'react'

const Home = lazy(() => import('pages/Home'))
const Simulador = lazy(() => import('pages/Simulador'))
const CargaFinanciera = lazy(() => import('pages/CargaFinanciera'))
const GlobalComplementario = lazy(() => import('pages/GlobalComplementario'))

const contentRoutes = [
  {
    linkTo: '/#/',
    path: '/',
    name: 'Inicio',
    component: Home,
    icon: 'ph:trend-up-bold',
    showNavigate: true,
    protected: false,
  },
  {
    linkTo: '/#/simulador',
    path: '/simulador',
    name: 'Simulador',
    component: Simulador,
    icon: 'ph:trend-up-bold',
    showNavigate: true,
    protected: false,
  },
  {
    linkTo: '/#/carga-financiera',
    path: '/carga-financiera',
    name: 'Carga financiera',
    component: CargaFinanciera,
    icon: 'ph:trend-up-bold',
    showNavigate: true,
    protected: false,
  },
  {
    linkTo: '/#/impuesto-global-complementario',
    path: '/impuesto-global-complementario',
    name: 'Global Complementario',
    component: GlobalComplementario,
    icon: 'ph:trend-up-bold',
    showNavigate: true,
    protected: false,
  },
]

export default contentRoutes
