import React from 'react'
import PropTypes from 'prop-types'
import { Link, NavbarMenu, NavbarMenuItem } from '@nextui-org/react'

const MobileMenu = ({ routes = [], pathname = '' }) => {
  return (
    <NavbarMenu>
      {routes.map((item, index) => {
        if (!item.showNavigate) return null
        return (
          <NavbarMenuItem key={`${item}-${index}`}>
            <Link
              size="lg"
              href={item.linkTo}
              className={`${
                pathname === item.path
                  ? 'font-semibold text-primary'
                  : 'font-normal text-neutral-500'
              }`}
            >
              {item.name}
            </Link>
          </NavbarMenuItem>
        )
      })}
    </NavbarMenu>
  )
}

MobileMenu.propTypes = {
  routes: PropTypes.array.isRequired,
  pathname: PropTypes.string.isRequired,
}

export default MobileMenu
