import { useEffect, useState } from 'react'
import { Auth, Utils } from 'helpers'
import { useDispatch, useSelector } from 'react-redux'
import appRoutes from 'routes/app'
import contentRoutes from 'routes/content'

const useAuth = () => {
  const [loading, setLoading] = useState(false)
  const [session] = useState(JSON.parse(localStorage.getItem('session')))

  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const routes = [...appRoutes, ...contentRoutes]
  const path = window.location.hash.split('#')[1] || '/'

  const validateSession = () => {
    const isProtected = routes.find((route) => route.path === path)?.protected
    const isExpired = session && new Date(session.expirationDate) <= new Date()
    if (!user) {
      dispatch({ type: 'set', user: session })
    }
    if (isExpired) {
      handleExpiredSession()
    }
    if (isProtected && !session) {
      handleProtectedRoute()
    }
  }

  const handleExpiredSession = () => {
    dispatch({ type: 'set', user: null })
    localStorage.clear()
    signOut()
  }

  const handleProtectedRoute = () => {
    dispatch({ type: 'set', user: null })
    Utils.redirect('/#/login')
  }

  useEffect(() => {
    validateSession()
  }, [session])

  const signIn = (email, password) => Auth.signIn(email, password, setLoading)
  const signUp = (user) => Auth.signUp(user, setLoading)
  const confirmSignUp = (email, code) => Auth.confirmSignUp(email, code, setLoading)
  const signOut = () => Auth.signOut(dispatch)

  return {
    signIn,
    signOut,
    signUp,
    confirmSignUp,
    loading,
    session,
  }
}

export default useAuth
